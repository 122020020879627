<template>
  <v-app>
    <v-main>
      <h2>PAGE NOT FOUND</h2>
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style>
</style>